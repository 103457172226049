import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const BABY_ERP_LIGHT_ENDPOINT = 'https://demo.insonix.com:3026';

// http://103.27.234.196:3025

export const getAPI = createAsyncThunk(
    'dashBoard',
    async () => {
        const response = await axios.get('https://reqres.in/api/users?page')
        return response.data
    }
)

export const registerCustomer = createAsyncThunk(
    'dashBoard',
    async (payload) => {
        const response = await axios.post(`${BABY_ERP_LIGHT_ENDPOINT}/security/register-customer`, payload, {
            headers: HEADERS.BASIC(),
        })
        return response.data
    }
)

export const verifyCustomerOTP = createAsyncThunk(
    'dashBoard',
    async (payload) => {
        const response = await axios.post(`${BABY_ERP_LIGHT_ENDPOINT}/security/verify-customer`, payload, {
            headers: HEADERS.BASIC(),
        })
        return response.data
    }
)

export const getStoreList = createAsyncThunk(
    'storeList',
    async (payload) => {
        const response = await axios.post(`${BABY_ERP_LIGHT_ENDPOINT}/customer/store/M`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data
    }
)

export const getLocationBySearchText = async (searchText) => {
    return await axios.post(`https://blinkit.com/mapAPI/autosuggest_google?lat=28.4652382&lng=77.0615957&query=${searchText}`, {
        headers: HEADERS.BASIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getPlaceDetailByID = async (placeId) => {
    return await axios.post(`https://blinkit.com/mapAPI/place-detail?placeId=${placeId}`, {
        headers: HEADERS.BASIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const loginAsGuestUser = async (data) => {
    return await axios.post(`${BABY_ERP_LIGHT_ENDPOINT}/security/auto-login`, data, {
        headers: HEADERS.BASIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getStoreByKeyCode = async (storeCode) => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/customer/business/${storeCode}`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getStoreProductList = async (storeId) => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/customer/store/${storeId}/products`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getCustomerAddresses = async () => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/customer/address`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const createOrder = async (data) => {
    return await axios.post(`${BABY_ERP_LIGHT_ENDPOINT}/customer/order`, data, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const createAddress = async (data) => {
    return await axios.post(`${BABY_ERP_LIGHT_ENDPOINT}/customer/address`, data, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getDeliveryMethods = async (storeId) => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/customer/store/${storeId}/delivery-methods`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getOrderHistory = async () => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/customer/orders/summary`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getStoresOTPs = async () => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/business/customer/otps`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}

export const getProductById = async (productId) => {
    return await axios.get(`${BABY_ERP_LIGHT_ENDPOINT}/customer/product/${productId}`, {
        headers: HEADERS.AUTHENTIC(),
    }).then(function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return { status: response.status }
        }
    }).catch(function (error) {
        return { status: error }
    });
}