import React, { useState } from 'react';
import { Card, Alert, Col, Row, Button, Form, Modal, FloatingLabel, Accordion, Image, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createNewAddress } from '../../slice/dashBoardSlice';

function AddAndEditAddress(props) {

    const dispatch = useDispatch();
    const [addDetails, setAddressDetails] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddressDetails({ ...addDetails, [name]: value })
    }


    const addAddress = () => {
        if (addDetails.personName && addDetails.line1 && addDetails.mobileNumber) {
            let json = {
                "personName": addDetails.personName,
                "line1": addDetails.line1,
                "line2": addDetails.line2 ? addDetails.line2 : "",
                "mobileNumber": addDetails.mobileNumber ? addDetails.mobileNumber : "",
                "stateCode": "03",
                "locationLat": "30.746343",
                "locationLong": "75.2718205",
                "status": "ACTIVE"
            }
            dispatch(createNewAddress(json)).then((res) => {
                props.onCloseButton('refresh');
            })
        } else {
            alert("Please select mandatory fiels...");
        }
    }


    return (
        <>

            <Modal show={props.addeditshow} centered size='md' className='my_modal' >
                {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label="Name" controlId="floatingTextarea" >
                                <Form.Control name='personName' className='shadow-box' type="text" placeholder="name" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label="Address" controlId="floatingTextarea" >
                                <Form.Control name='line1' className='shadow-box' type="text" placeholder="Address Line 1" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label="" controlId="floatingTextarea" >
                                <Form.Control name='line2' className='shadow-box' type="text" placeholder="Address Line 2" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="" label="Mobile" controlId="floatingTextarea" >
                                <Form.Control name='mobileNumber' className='shadow-box' type="text" placeholder="Mobile" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Button variant='primary' className='light-btn bg-light-green text-white' onClick={addAddress}>
                        Save
                    </Button>
                    <Button variant='primary' className='light-btn bg-light-blue text-white' onClick={props.onCloseButton}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default (AddAndEditAddress);
