import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Carousel, Col, Image, Placeholder, ProgressBar, Row } from 'react-bootstrap';
import nonVegIcon from '../../assets/images/non-vegetarian-food-symbol.png';
import VegIcon from '../../assets/images/vegetarian-food-symbol.png';
import bizLogo from '../../assets/images/mf-logo.png';
import offerImg from '../../assets/images/pngegg.png';
import { useNavigate } from 'react-router-dom';
import { fetchOrderHistory } from '../../slice/dashBoardSlice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import ReviewModal from './ReviewModal';
import ExitReviewModal from './ExitReviewModal';
import SuccessModal from './SuccessModal';

function BrandInformation() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const baseUrl = localStorage.getItem('baseUrl');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState(false);

    const orderDetail = [{ orderNum: '5037955816', payStatus: 'InComplete', actionClr: 'N', mobileNum: '75081*****', dateTime: '19 july 2023 at 12:47 PM', address: 'Big ben, New Town, Moga, Punjab, India' }
    ]

    const [orderDetails, setOrderDetails] = useState({});

    const [favoriteIcon, setFavoriteIcon] = useState(false);
    const [likeIcon, setLikeIcon] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);

    const [ratingText, setRatingText] = useState([]);
    const [hover, setHover] = useState(0);

    const [progressBar, setProgressBar] = useState([
        { name: 'Taste', now: 25, barClr: 'bar-red' },
        { name: 'Portion size', now: 50, barClr: 'bar-green' },
        { name: 'Packaging', now: 40, barClr: 'bar-green' },
        { name: 'Value for money', now: 80, barClr: 'bar-green' },
    ]);

    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showExitReviewModal, setShowExitReviewModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [commentCards, setCommentCards] = useState([
        { name: 'Hargun Singh', location: 'Moga, Punjab', totalRating: 5, message: 'all items are tasty and packing is too good thanks Master Food.', dayTime: '1 month ago' },
        { name: 'Maninder Singh', location: 'Moga, Punjab', totalRating: 1, message: 'very bad', dayTime: '1 month ago' },
        { name: 'Jashanpreet Singh', location: 'Moga, Punjab', totalRating: 3, message: 'the chicken was very rubbery. not satisfied', dayTime: '9 days ago' },
    ]);

    // useEffect(() => {
    //     getOrders();
    // }, []);

    // const getOrders = () => {
    //     dispatch(fetchOrderHistory()).then((response) => {
    //         let order = response.payload.find(order => order.id === searchParams.get('orderId'));
    //         setOrderDetails(order);
    //         setDataLoaded(true);
    //     });
    // }

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setDataLoaded(true);
            }, 2000);
        } else {
            setTimeout(() => {
                setDataLoaded(true);
            }, 2000);
        }
    }, []);

    const iconClassName = (status) => {
        if (status === 'ACTIVE' || status === 'COMPLETED') {
            return 'circle-check';
        } else if (status === 'FAILED') {
            return 'circle-exclamation';
        } else if (status === 'CANCELLED') {
            return 'times-circle';
        } else {
            return '';
        }
    }

    const colorClassName = (status) => {
        if (status === 'ACTIVE' || status === 'COMPLETED') {
            return 'text-green';
        } else {
            return 'text-red';
        }
    }

    const toggleIcon = () => {
        setFavoriteIcon(!favoriteIcon);
        if ("vibrate" in navigator) {
            navigator.vibrate(50);
        }
    };


    const toggleLikeIcon = () => {
        setLikeIcon(!likeIcon);
    };

    const onOpenReviewModal = () => {
        setShowReviewModal(true);
    }

    // const onCloseReviewModal = () => {
    //    setShowReviewModal(false); 
    // }

    const onOpenExitReviewModal = () => {
        setShowExitReviewModal(true);
    }

    const onCloseExitReviewModal = () => {
        setShowExitReviewModal(false);
        setShowReviewModal(false);
    }

    const onCloseBackReviewModal = () => {
        setShowExitReviewModal(false);
    }

    const onOpenSuccessModal = () => {
        if (showReviewModal === true) {
            setShowSuccessModal(true);

            setTimeout(() => {
                setShowSuccessModal(false);
                setShowReviewModal(false);
            }, 4000);
        }

    }

    return (
        <>
            <Row className='g-0 justify-content-center brandInformation'>
                {/* <span className=' bg-img' style={{zIndex:'-1'}}></span> */}
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' onClick={() => navigate('/home')} className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                            {/* <h5 className='mb-0 w-100 text-center fs-6 pe-4'>Brand Information</h5> */}
                            <Button size='sm' className='bg-transparent text-dark border-0 ms-auto' onClick={() => toggleIcon()}>
                                <i class={`${favoriteIcon === true ? 'fas fa-heart text-yellow' : 'far fa-heart'}`}></i>
                            </Button>
                            <Button size='sm' className='bg-transparent text-dark border-0' >
                                <i class="fas fa-share-nodes"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        <Col xs={12} md={12} className=''>
                            <Card className='cardItem mb-2' >
                                <Card.Body className='p-2 py-3'>
                                    <Row className='g-0'>
                                        <Col xs={12} className=''>
                                            <div className='text-center mb-3'>
                                                <Button variant='primary' className='mb-2 mx-auto light-btn brand_img img_size' >
                                                    <Image className='' src={bizLogo} />
                                                </Button>
                                                <Card.Title className='fs-6 fw-bold mb-0'>Master Food</Card.Title>
                                                <span className='fs-12 text-blue'>Cafe - Burger - Fast Food</span>
                                            </div>
                                            <Row className='mb-3 px-1 justify-content-center'>
                                                <Col xs={4} sm={3} md={4} lg={4} className='d-flex align-items-center'>
                                                    <div className="d-flex align-items-start" >
                                                        <span className="me-1 text-blue">
                                                            <i class="fas fa-location-dot fs-12"></i>
                                                        </span>
                                                        <span className="">
                                                            <span className="fs-12 text-blue">1.5 km</span>
                                                            <span className='d-block fs-12'>Distance</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={3} md={4} lg={4} className='d-flex align-items-center  border border-top-0 border-bottom-0'>
                                                    <div className="d-flex align-items-start" >
                                                        <span className="small-ratings me-1">
                                                            <i class="fas fa-star rating-color"></i>
                                                        </span>
                                                        <span className="">
                                                            <span className="fs-12 text-blue">4.4</span>
                                                            <span className='d-block fs-12'>124 ratings</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={3} md={4} lg={4}>
                                                    <div className="d-flex align-items-start" >
                                                        <span className="me-1 text-blue">
                                                            <i class="fas fa-clock fs-12"></i>
                                                        </span>
                                                        <span className="">
                                                            <span className="fs-12 text-blue">30 mins</span>
                                                            <span className='d-block fs-12'>Delivery Time</span>
                                                        </span>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="text-center rounded mt-2 p-1" style={{ background: '#a8a8a824' }}>
                                                <span className="fs-12">Opens at 9 am</span>
                                            </div>
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col xs={12} md={12} className='mt-3'>
                            <h5 className='fs-14 fw-bold'>Daily offers</h5>
                        </Col>
                        <Col xs={12}>
                            <Carousel className='offer_carousel'>
                                <Carousel.Item interval={1000} className='pe-1' role="button">
                                    <Card className='cardItem bg-light-blue '>
                                        <Card.Body className='p-2'>
                                            <Row className='g-0'>
                                                <Col xs={12} className='d-flex flex-wrap align-items-center text-white'>
                                                    <div className='ps-1'>
                                                        <span className="d-block fs-14 fw-bold mb-1">Free Combo</span>
                                                        <span className="d-block fs-12">Burger + Coca Cola</span>
                                                        <span className="d-block fs-12">for new users</span>
                                                    </div>
                                                    <Image className='ms-auto' width="60px" height="60px" src={offerImg} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Carousel.Item>
                                <Carousel.Item interval={1000} className='pe-1' role="button">
                                    <Card className='cardItem bg-light-green '>
                                        <Card.Body className='p-2'>
                                            <Row className='g-0'>
                                                <Col xs={12} className='d-flex flex-wrap align-items-center text-white'>
                                                    <div className='ps-1'>
                                                        <span className="d-block fs-14 fw-bold mb-1">Free Combo</span>
                                                        <span className="d-block fs-12">Burger + Coca Cola</span>
                                                        <span className="d-block fs-12">for new users</span>
                                                    </div>
                                                    <Image className='ms-auto' width="60px" height="60px" src={offerImg} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Carousel.Item>
                                <Carousel.Item interval={1000} className='pe-1' role="button">
                                    <Card className='cardItem bg-light-red '>
                                        <Card.Body className='p-2'>
                                            <Row className='g-0'>
                                                <Col xs={12} className='d-flex flex-wrap align-items-center text-white'>
                                                    <div className='ps-1'>
                                                        <span className="d-block fs-14 fw-bold mb-1">Free Combo</span>
                                                        <span className="d-block fs-12">Burger + Coca Cola</span>
                                                        <span className="d-block fs-12">for new users</span>
                                                    </div>
                                                    <Image className='ms-auto' width="60px" height="60px" src={offerImg} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col xs={12} md={12} className='mt-3'>
                            <h5 className='fs-14 fw-bold'>About us</h5>
                        </Col>
                        <Col xs={12}>
                            <Card className='cardItem mb-2' >
                                <Card.Body className='p-2'>
                                    <Row className='g-0 py-2'>
                                        <Col xs={12} md={12}>
                                            <span className='d-flex flex-wrap align-items-center'>
                                                <div className='me-auto pe-3 '>
                                                    <p className="fs-12 mb-0 text-start">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>                                                </div>
                                            </span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        <Col xs={12} md={12} className=''>
                            <Card className='cardItem mb-2' >
                                <Card.Body className='p-2'>
                                    <Row className='g-1 py-2'>
                                        {/* <Col xs={12} className='mt-2'>
                                            <div className="d-flex align-items-center mb-2" >
                                                <Badge className="bg-light-blue">
                                                    <span className="fs-12">4.4</span>
                                                    <span className="small-ratings ms-1">
                                                        <i class="fas fa-star text-white"></i>
                                                    </span>
                                                </Badge>
                                                <span className='fs-12 text-blue ms-2'>- 124 Ratings</span>
                                            </div>
                                        </Col> */}
                                        {progressBar.map((item) => {
                                            return (
                                                <Col xs={12} className=''>
                                                    <Row className='g-0'>
                                                        <Col xs={4} className=''>
                                                            <span className="fs-12">{item.name}</span>
                                                        </Col>
                                                        <Col xs={8} className='d-flex align-items-center'>
                                                            <Row className='g-0 d-flex align-items-center w-100'>
                                                                <Col xs={10} className='pe-2'>
                                                                    <ProgressBar className={`progress_bar ${item.barClr}`} now={item.now} />
                                                                </Col>
                                                                <Col xs={2} className=''>
                                                                    <span className='fs-12 text-blue d-flex align-items-center'>{item.now}%</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        {commentCards.map((item, idx) => {
                            return (
                                <>
                                    <Col key={idx} xs={12} md={12} className=''>
                                        <Card className='cardItem mb-2' >
                                            <Card.Body className='p-2'>
                                                <Row className='g-0'>
                                                    <Col xs={12} className='my-2'>
                                                        <span className='d-flex flex-wrap align-items-start'>
                                                            <div className='me-auto pe-2'>
                                                                <Card.Title className='fs-14 fw-bold mb-0'>{item.name}</Card.Title>
                                                                <span className='fs-12 text-blue'>{item.location}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-end ms-auto">
                                                                <Badge className={item.totalRating > 1 ? 'bg-light-green' : 'bg-light-red'}>
                                                                    <span className="fs-12">{item.totalRating}</span>
                                                                    <span className="small-ratings ms-1">
                                                                        <i class="fas fa-star text-white"></i>
                                                                    </span>
                                                                </Badge>
                                                            </div>
                                                        </span>
                                                    </Col>
                                                    <Col xs={12} className=''>
                                                        <p className='fs-14 mb-2'>{item.message}</p>
                                                    </Col>
                                                    <Col xs={12} className='border border-bottom-0 border-start-0 border-end-0'>
                                                        <span className='d-flex flex-wrap align-items-start'>
                                                            <div className='me-auto pe-2'>
                                                                <span className='fs-12 text-blue'>{item.dayTime}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-end ms-auto">
                                                                <Button size='sm' className='bg-transparent text-blue border-0 fs-12' onClick={() => toggleLikeIcon(idx)}>
                                                                    <i class={`${likeIcon === true ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'} me-1 fs-14`}></i>154
                                                                </Button>
                                                            </div>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                    <Row className='g-0 position-relative'>
                        <Col xs={12} lg={6} className='px-3 btn-position'>
                            {/* {dataLoaded === true ? */}
                            <Button onClick={onOpenReviewModal} className='w-100 py-3 foot-btn bg-light-blue text-truncate'>
                                Review
                            </Button>
                            {/* :
                                <Placeholder animation="glow" as='row' className='g-0'>
                                    <Placeholder.Button xs={12} className='rounded' style={{ height: '56px' }} />
                                </Placeholder>} */}
                        </Col>
                    </Row>
                </Col >
            </Row >
            <ExitReviewModal showExitReviewModal={showExitReviewModal} onCloseExitReviewModal={onCloseExitReviewModal} onCloseBackReviewModal={onCloseBackReviewModal} />

            <ReviewModal showReviewModal={showReviewModal} onCloseReviewModal={onCloseExitReviewModal} onOpenExitReviewModal={onOpenExitReviewModal} onOpenSuccessModal={onOpenSuccessModal} />

            <SuccessModal showSuccessModal={showSuccessModal} />

        </>
    )
}


export default (BrandInformation);
