import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Nav, Placeholder, Row } from 'react-bootstrap';
import img1 from '../../assets/images/img-one.jpg';
import img2 from '../../assets/images/img-two.jpg';
import img3 from '../../assets/images/img-three.jpg';
import img4 from '../../assets/images/img-four.jpg';
import img5 from '../../assets/images/img-five.jpg';
import img6 from '../../assets/images/img-six.jpg';
import img7 from '../../assets/images/img-seven.jpg';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import shareIcon from '../../assets/images/whatsapp.png';
import { getProductById } from '../../actions/DashBoard/getDashBoardData';
import { useDispatch } from 'react-redux';
import { getProductDetails } from '../../slice/dashBoardSlice';

const urlLink = 'https://www.youtube.com/embed/PdJq-dAQr-Y?si=ZtIolBVeYN-QMdFc';

var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
};

function ProductDetails() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [prodDataObject, setProdDataObject] = useState({});

    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewLink, setPreviewLink] = useState('');
    const [fileList, setFileList] = useState([
        { url: img1 },
        { url: img2 },
        { url: img3 },
        { url: img4 },
        { url: img5 },
        { url: img6 },
        { url: img7 },
    ]);

    const [unitList, setUnitList] = useState([
        { size: 'S ', color: 'Black' },
        { size: 'M ', color: 'Black' },
        { size: 'L ', color: 'Black' },
        { size: 'XL', color: 'Black' },
    ]);

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setDataLoaded(true);
            }, 2000);
        } else {
            setTimeout(() => {
                setDataLoaded(true);
            }, 2000);
        }
        getProductDetailsWithId();
    }, []);

    const getProductDetailsWithId = () => {
        const searchParams = new URLSearchParams(window.location.search);
        if (localStorage.getItem('prdId') || searchParams.get('id')) {
            let prdId = localStorage.getItem('prdId') ? localStorage.getItem('prdId') : searchParams.get('id');
            try {
                dispatch(getProductDetails(prdId)).then((response) => {
                    setProdDataObject(response.payload);
                })
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handlePreview = (url, idx) => {
        setActiveItemIndex(idx);
        if (url && url.includes('youtube')) {
            setPreviewLink(url);
            setPreviewImage('');
        } else {
            setPreviewImage(url);
            setPreviewLink('');
        }
    };
    
    const handleShareClick = () => {
        const textToShare = encodeURIComponent(
            'https://difoo.insonix.com/productdetails?id=' + localStorage.getItem('prdId')
        );
        const whatsappUrl = `whatsapp://send?text=${textToShare}`;
        window.location.href = whatsappUrl;
    };

    return (
        <>
            <Row className='g-0 justify-content-center cart'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            {/* <Button size='sm' onClick={() => handleBack()} className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button> */}
                            <h5 className='mb-0 w-100 fs-6'>Product Details</h5>
                            {/* <Button size='sm' className='bg-transparent text-green border-0' onClick={() => handleShareClick()}>
                                <i class="fa-brands fa-square-whatsapp"></i>
                            </Button> */}
                            <Button size='sm' onClick={() => handleBack()} className='bg-transparent text-dark border-0'>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <>
                                {/* <Col xs={12} md={12} className=''>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <Button size='sm' className='bg-transparent p-0 fs-16 ms-auto text-green border-0'>
                                            <i class="fa-brands fa-square-whatsapp"></i>
                                        </Button>
                                    </div>
                                </Col> */}
                                <Col xs={12}>
                                    <Card className='cardItem mb-2'>
                                        <Card.Body>
                                            <Row className='g-0'>
                                                <Col xs={12} sm={12} className='mb-3'>
                                                    <div className='image-container overflow-hidden'>
                                                        <div className='mb-4'>
                                                            {previewLink ? (
                                                                <iframe width={300} height={300} className='rounded d-block mx-auto' src={previewLink} frameBorder="0" allowFullScreen></iframe>
                                                            ) : (
                                                                <Image width={300} height={300} className='rounded d-block mx-auto' src={previewImage || img1} />
                                                            )}
                                                        </div>
                                                        <Nav className="px-2">
                                                            <Slider className='item-nav-tabs' {...settings}>
                                                                {fileList.map((file, idx) => (
                                                                    <Nav.Item key={idx} onClick={() => handlePreview(file.url, idx)}>
                                                                        <Nav.Link eventKey={idx}
                                                                            active={false}
                                                                        // active={activeItemIndex === idx ? true : false}
                                                                        >
                                                                            <Image width={60} height={60} className='rounded d-block mx-auto' style={{ objectFit: 'cover' }} src={file.url} alt={file.name} />
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                                <Nav.Item onClick={() => handlePreview(urlLink)}>
                                                                    <Nav.Link active={false}>
                                                                        <iframe className='pe-none rounded d-block mx-auto' width={60} height={60} style={{ objectFit: 'cover' }} src={urlLink} frameBorder="0" allowFullScreen></iframe>
                                                                    </Nav.Link>
                                                                </Nav.Item>

                                                            </Slider>
                                                        </Nav>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} className=''>
                                                    <Card.Title className='fs-14 fw-bold mb-0'>{prodDataObject.prodAlias}</Card.Title>
                                                    <hr className='' />
                                                </Col>
                                                <Col xs={12} sm={12} className='position-relative'>
                                                    {/* <Card.Title className='fs-14 fw-bold me-3'>Select Unit</Card.Title> */}
                                                    {/* <Row className='g-0'>
                                                        <Col xs={12} sm={12} className='mb-3'>
                                                            <Nav className="px-2">
                                                                <Slider className='item-nav-tabs slider-list' {...settings}>
                                                                    {unitList.map((item, idx) => (
                                                                        <>
                                                                            <Button variant='primary' className='light-btn p-2 w-100'>
                                                                                <span className="fs-12 d-block">{item.size}</span>
                                                                                <span className="fs-12 d-block">{item.color}</span>
                                                                            </Button>
                                                                        </>
                                                                    ))}
                                                                </Slider>
                                                            </Nav>
                                                        </Col>
                                                    </Row> */}
                                                    <span className='fs-14 d-flex flex-wrap align-items-center mb-2'>
                                                        <span className='me-auto pe-3'>
                                                            {/* <span className="fs-12 fw-bold text-blue">XL - 1 Piece</span>
                                                            <h4 className="fs-14 fw-bold mb-0"><i class="fas fa-indian-rupee-sign fs-12"></i>{prodDataObject.price}
                                                                <span className="fs-12 text-blue ms-2">MRP
                                                                    <span className="text-decoration-line-through ms-1">
                                                                        <i class="fas fa-indian-rupee-sign fs-12"></i>{prodDataObject.price}</span>
                                                                </span>
                                                            </h4>
                                                            <span className="fs-10 text-blue">(Inclusive of all taxes)</span> */}

                                                            <div className='mb-2'>
                                                                <h4 className="fs-12 text-blue">Price</h4>
                                                                <h4 className="fs-14 fw-bold mb-0"><i class="fas fa-indian-rupee-sign fs-12"></i>{prodDataObject.price}
                                                                    <span className="fs-12 text-blue ms-2">MRP
                                                                        <span className="text-decoration-line-through ms-1">
                                                                            <i class="fas fa-indian-rupee-sign fs-12"></i>{prodDataObject.price}</span>
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                        </span>
                                                        <Button variant='primary' className='ms-auto light-btn bg-white p-2 d-flex align-items-center' onClick={() => handleShareClick()}>
                                                            <i class="fa-brands fa-square-whatsapp text-green fs-2"></i>
                                                        </Button>
                                                        {/* <span className='ms-auto ps-2'>
                                                            <Card className='card-item addQty_card'>
                                                                {addQty === true ?
                                                            <>
                                                                <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={this.dec}>
                                                                    <i class="fas fa-minus"></i>
                                                                </Button>
                                                                <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={this.state.count} />
                                                                <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={this.inc}>
                                                                    <i class="fas fa-plus"></i>
                                                                </Button>
                                                            </>
                                                            :
                                                            <Button className='border-0 bg-transparent text-dark fs-12 fw-bold' >
                                                                <i class="fas fa-plus"></i> Add
                                                            </Button>
                                                        }
                                                            </Card>
                                                        </span> */}
                                                    </span>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <div className='mb-2'>
                                                        <h4 className="fs-12 text-blue  mb-0">Descritpion</h4>
                                                        <span className="fs-12">{prodDataObject.description}</span>
                                                    </div>
                                                </Col>
                                                {/* <Col xs={12} sm={12}>
                                                    <Card.Title className='fs-14 fw-bold'>Product Details</Card.Title>
                                                    <div className='mb-2'>
                                                        <h4 className="fs-12 text-blue  mb-0">Brand</h4>
                                                        <span className="fs-12">Polo</span>
                                                    </div>
                                                    <div id="details" className="details collapse">
                                                        <div className='mb-2'>
                                                            <h4 className="fs-12 text-blue  mb-0">Size</h4>
                                                            <span className="fs-12">2XL</span>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <h4 className="fs-12 text-blue  mb-0">Colour</h4>
                                                            <span className="fs-12">Black</span>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <h4 className="fs-12 text-blue  mb-0">Descritpion</h4>
                                                            <span className="fs-12">{prodDataObject.description}</span>
                                                        </div>
                                                    </div>
                                                    <Button variant='primary' className='detail_btn border-0 bg-transparent text-blue fs-12  p-0' data-bs-toggle="collapse" data-bs-target="#details">
                                                        more details<span className='ms-1'><i class="fas fa-caret-down"></i></span>
                                                    </Button>
                                                </Col> */}
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                            :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Card className='cardItem mb-2 bg-transparent shadow-none'>
                                    <Card.Body>
                                        <Placeholder xs={12} className='rounded mb-4' style={{ width: '100%', height: '300px' }} />
                                        <span className='mb-3' style={{ display: 'flex', gap: '7px' }}>
                                            <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                            <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                            <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                            <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                        </span>
                                        <Placeholder xs={12} className='' />
                                        <Placeholder as='hr' className='' style={{ width: '100%', minHeight: '2px' }} />
                                        {/* <Placeholder xs={12} className='mb-3' style={{ width: '25%' }} />
                                        <span className='mb-3' style={{ display: 'flex', gap: '7px' }}>
                                            <Placeholder className='rounded' style={{ width: '100%', height: '52px' }} />
                                            <Placeholder className='rounded' style={{ width: '100%', height: '52px' }} />
                                            <Placeholder className='rounded' style={{ width: '100%', height: '52px' }} />
                                            <Placeholder className='rounded' style={{ width: '100%', height: '52px' }} />
                                        </span>
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '25%' }} />
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '35%' }} />
                                        <Placeholder xs={12} className='d-block mb-3' style={{ width: '25%', minHeight: '10px' }} />
                                        <Placeholder xs={12} className='d-block mb-2' style={{ width: '35%' }} />
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '25%' }} />
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '25%' }} /> */}
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '25%' }} />
                                        <Placeholder xs={12} className='d-block mb-3' style={{ width: '35%' }} />
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '25%' }} />
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '35%' }} />
                                    </Card.Body>
                                </Card>
                            </Placeholder>}
                    </Row >
                </Col>
            </Row>
        </>
    )
}


export default (ProductDetails);
