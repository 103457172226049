import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAPI, registerCustomer, verifyCustomerOTP, getStoreList, getLocationBySearchText, getOrderHistory, getPlaceDetailByID, loginAsGuestUser, getStoreByKeyCode, getStoreProductList, getCustomerAddresses, createOrder, createAddress, getDeliveryMethods, getStoresOTPs, getProductById } from '../actions/DashBoard/getDashBoardData';

const initialState = {
    dashBoardData: [],
    customerRegister: "",
    mobileNumber: "",
    storeList: []
}



export const fetchLocationBySearchText = createAsyncThunk(
    'dashBoard/load',
    async (searchText) => {
        const response = await getLocationBySearchText(searchText);
        return response;
    }
)

export const fetchPlaceDetailByID = createAsyncThunk(
    'place-detail/load',
    async (placeId) => {
        const response = await getPlaceDetailByID(placeId);
        return response;
    }
)

export const loginWithGuest = createAsyncThunk(
    'guest-login/load',
    async (data) => {
        const response = await loginAsGuestUser(data);
        return response;
    }
)

export const fetchStoreByKeyCode = createAsyncThunk(
    'storeCode',
    async (storeCode) => {
        const response = await getStoreByKeyCode(storeCode);
        return response;
    }
)

export const getStoreProducts = createAsyncThunk(
    'getStoreProducts',
    async (storeId) => {
        const response = await getStoreProductList(storeId);
        return response;
    }
)

export const fetchStoreDeliveryMethods = createAsyncThunk(
    'fetchStoreDeliveryMethods',
    async (storeId) => {
        const response = await getDeliveryMethods(storeId);
        return response;
    }
)

export const getCustomerAddressList = createAsyncThunk(
    'getCustomerAddressList',
    async () => {
        const response = await getCustomerAddresses();
        return response;
    }
)

export const createNewOrder = createAsyncThunk(
    'createNewOrder',
    async (data) => {
        const response = await createOrder(data);
        return response;
    }
)

export const createNewAddress = createAsyncThunk(
    'createNewAddress',
    async (data) => {
        const response = await createAddress(data);
        return response;
    }
)

export const fetchOrderHistory = createAsyncThunk(
    'fetchOrderHistory',
    async (data) => {
        const response = await getOrderHistory(data);
        return response;
    }
)

export const fetchStoresOTPs = createAsyncThunk(
    'fetchStoresOTPs',
    async (data) => {
        const response = await getStoresOTPs(data);
        return response;
    }
)

export const getProductDetails = createAsyncThunk(
    'getProductDetails',
    async (data) => {
        const response = await getProductById(data);
        return response;
    }
)

export const dashboardSlice = createSlice({
    name: 'dashBoard',
    initialState,

    reducers: {
        changeDashBoardData: (state, action) => {
            state.dashBoardData = action.payload;
        },
        setCurrentMobileNumber: (state, action) => {
            state.dashBoardData = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getAPI.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getAPI.fulfilled, (state, action) => {
            state.dashBoardData = action.payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registerCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(registerCustomer.fulfilled, (state, action) => {
            state.customerRegister = action.payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(verifyCustomerOTP.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(verifyCustomerOTP.fulfilled, (state, action) => {
            state.customerRegister = action.payload
        })

    },
    extraReducers: (builder) => {
        builder.addCase(getStoreList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getStoreList.fulfilled, (state, action) => {
            state.storeList = action.payload
        })

    },

});

export const { changeDashBoardCardData, setCurrentMobileNumber } = dashboardSlice.actions

export default dashboardSlice.reducer;